/* global SDKHelper:true */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { conf } from '../domain/services/configService';
import { useJsDependencies } from '../hooks/useJsDependencies';
import { getSDKScriptUrl } from './WithVideoSDK';
import { WithCmpVideoSettings } from './WithCmpVideoSettings';
import { adService } from '../domain/services/adService';
import { imageResizerService } from '../domain/services/imageResizerService';
import { breakpointService } from '../domain/services/breakpointService';

export const StandaloneVideoPlayer = WithCmpVideoSettings(
  ({ videoEntity, content }) => {
    const videoId = videoEntity.guid;
    const scriptUrl = getSDKScriptUrl();
    const areJsDepsLoaded = useJsDependencies(
      {
        [scriptUrl]: () => 'SDK' in window
      },
      { loadInOrder: true }
    );
    const videoThumb =
      videoEntity.imageEntity && videoEntity.imageEntity.mediaImage
        ? imageResizerService.getStyleUrl(
            videoEntity.imageEntity.mediaImage.url,
            breakpointService.getLargestBreakpoint().width,
            null,
            {
              position: videoEntity.imageEntity.focalPoint
            }
          )
        : '';
    const playerOptions = {
      autoplay: true,
      country: conf.siteCountryCode.toUpperCase(),
      id: videoId,
      site_section_id: 'FIC_standalone',
      template: conf.VideoPlayerTemplate,
      adobe_enabled: true,
      google_enabled: ['br', 'mx'].includes(conf.siteCountryCode),
      ads: !videoEntity.noAds,
      video_title: videoEntity.title,
      video_thumb: videoThumb
    };

    playerOptions.language = conf.langcode.toUpperCase();

    const targeting = adService.getTargeting(content);
    playerOptions.gam = {
      // Video Player auto populates the account id of the AdUnitPath, so let's remove it.
      iu: adService.getAdUnitPath(content).replace(/^\/\d+\//, '/'),
      vid: targeting.objid,
      tstadtar: targeting.tstAdTar,
      tag: targeting.tag,
      status: 'enabled'
    };
    playerOptions.releaseUrl = { policy: '131226695' };
    if (conf.age_verification) {
      playerOptions.age_verification = conf.age_verification;
      playerOptions.autoplay = 'false';
    }
    window.fng_player_subtitles_plugin = true;
    window.fng_player_ga_plugin = false;
    // window.fng_player_gam_plugin = true;
    window.fng_player_agecheck_plugin = true;
    window.fng_player_unmute_plugin = true;
    window.fng_player_subtitle_plugin = true;
    useEffect(() => {
      const sdkContainerId = `ng-standalone-player-${videoId}`;

      if ('SDKHelper' in window) {
        try {
          const player = new window.SDK(`#${sdkContainerId}`, playerOptions, [
            SDKHelper?.setPlayerUrl,
            SDKHelper?.setBrowserData
          ]);
          player.init();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Error initializing Standalone Video', error);
        }
      }
    }, [areJsDepsLoaded]);

    const styles = {
      standaloneVideo: css`
        width: 100%;
        video {
          aspect-ratio: 16 / 9;
        }
      `,
      playerContainer: css`
        width: 100%;
        display: block;
        position: relative;
        aspect-ratio: 16 / 9;
        overflow: hidden;
        .tpPlayer {
          height: initial !important;
          aspect-ratio: 16 / 9;
        }
      `
    };

    return (
      <div css={styles.playerContainer}>
        <div
          id={`ng-standalone-player-${videoId}`}
          css={styles.standaloneVideo}
        />
      </div>
    );
  }
);

StandaloneVideoPlayer.propTypes = {
  videoEntity: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
