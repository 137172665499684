import { useState, useRef, useEffect } from 'react';
import { addScript, getScriptDomElement } from '../domain/utils/domUtils';

const processedScripts = [];

/**
 * @typedef Options
 * @type {object}
 * @property {boolean} returnValueForSSR
 *    Default value: false. Since we cannot load any external JS on SSR, we should define what
 *    should be returned, depending on our use case and the tags we want to render unconditionally.
 */

/**
 * Hook for loading required external JS files.
 *
 * @param {Object.<string, function>} jsDeps An object with JS urls as keys, and a validation function as value
 * @param {Options} options An object with options
 *
 * @example
 * const areJsDepsLoaded = useJsDependencies(
 *  {
 *    'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js': () =>
 *      'jQuery' in window,
 *     'https://player-staging.fichub.com/sdk/2.0.0/sdk.js': () =>
 *      'SDK' in window
 *  }
 * );
 *
 * return areJsDepsLoaded ? (<div>foo</div>) : null;
 */
export const useJsDependencies = (
  jsDeps,
  { loadInOrder = false, returnValueForSSR = false } = {}
) => {
  // We need to manually check if we are mounted (yes, this is an anti-pattern, we know it)
  // because jsLoadCallback() is executed from some events that cannot be removed from a
  // useEffect cleanUp function.
  const isMounted = useRef(false);

  // Remove dependencies that has been already loaded on any page.
  // One dependencies are loaded, they are not removed so we can
  // process them once.
  const jsDepsToProcess = {};
  Object.keys(jsDeps)
    .filter(jsUrl => !processedScripts.includes(jsUrl))
    .forEach(jsUrl => {
      jsDepsToProcess[jsUrl] = jsDeps[jsUrl];
    });

  // Create a validator function for all dependencies.
  // Use it as the initial state so we can bypass this hook if
  // dependencies are already loaded.
  const areAllValidationsPassing = () => {
    // On SSR, return a custom value.
    if (typeof window === 'undefined') {
      return returnValueForSSR;
    }

    const validations = Object.values(jsDepsToProcess).map(jsValidation =>
      jsValidation()
    );

    // Check if some validation has returned false, or if all of
    // them have returned true.
    return !validations.includes(false);
  };

  const [areJsDepsLoaded, setAreJsDepsLoaded] = useState(
    areAllValidationsPassing
  );

  const jsLoadCallback = () => {
    if (isMounted.current === true && areAllValidationsPassing()) {
      setAreJsDepsLoaded(true);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    if (!areJsDepsLoaded) {
      const jsDepsAlreadyNotLoaded = {};
      Object.keys(jsDepsToProcess).forEach(jsUrl => {
        const validator = jsDepsToProcess[jsUrl];
        if (validator()) {
          processedScripts.push(jsUrl);
        } else {
          jsDepsAlreadyNotLoaded[jsUrl] = validator;
        }
      });

      if (loadInOrder) {
        Object.keys(jsDepsAlreadyNotLoaded).forEach(jsUrl => {
          processedScripts.push(jsUrl);
        });
        addScript(Object.keys(jsDepsAlreadyNotLoaded), {
          async: false,
          callback: jsLoadCallback
        });
      } else {
        Object.keys(jsDepsAlreadyNotLoaded).forEach(jsUrl => {
          if (processedScripts.includes(jsUrl)) {
            const scriptDomElement = getScriptDomElement(jsUrl);
            if (scriptDomElement) {
              scriptDomElement.addEventListener('load', jsLoadCallback);
            }
          } else {
            processedScripts.push(jsUrl);
            addScript(jsUrl, { callback: jsLoadCallback });
          }
        });
      }
    }
    return () => {
      // Cannot remove the above event listeners and callbacks, so track if component is mounted.
      isMounted.current = false;
    };
  }, []);

  return areJsDepsLoaded;
};
