import React, { useState, useEffect } from 'react';
import { conf } from '../domain/services/configService';
import { consentService } from '../domain/services/consentService';
import { domainService } from '../domain/services/domainService';

const getDomain = () => {
  let domain;

  // Get the domain from the request and remove the extension.
  // Use canonicalDomain from configService when developing on local.
  if (typeof window === 'object') {
    domain = domainService.isLocalDomain(window.location.hostname)
      ? conf.canonicalDomain
      : window.location.hostname;
  } else if (conf.isStage) {
    domain = `cms-stage-${conf.langcode}.ngeo.com`;
  } else {
    domain = conf.canonicalDomain;
  }
  const matches = domain.match(/(.*?)((?:\.co)?.[a-z]{2,5})$/i);
  domain = matches[1]; // eslint-disable-line

  // Remove the www. prefix if it exists.
  domain = domain.replace('www.', '');

  // Get the language settings and sanitize country code.
  const countryCode = conf.siteCountryCode.toLowerCase();
  // Add the language code to the domain.
  domain = `${domain}.${countryCode}`;

  return domain;
};

export const WithCmpVideoSettings = Component => {
  return props => {
    const [state, setState] = useState({
      oneTrust: undefined
    });

    const handleRequirementsReady = () => {
      // Remove listener just in case it is defined.
      document.removeEventListener(
        'CmpRequirementsReady',
        handleRequirementsReady
      );

      // OneTrust data is always mandatory.
      // Data coming from OneTrust must be queried on every ad,
      // to be able to update it without a full page reload.
      setState({
        oneTrust: consentService.getPlayerData()
      });
    };

    useEffect(() => {
      if (window.CmpRequirementsReady) {
        handleRequirementsReady();
      } else {
        document.addEventListener(
          'CmpRequirementsReady',
          handleRequirementsReady
        );
      }

      // Always return a idempotent clean up.
      return () =>
        document.removeEventListener(
          'CmpRequirementsReady',
          handleRequirementsReady
        );
    }, []);

    const domain = getDomain();
    const countryCode = conf.siteCountryCode;
    if (state.oneTrust) {
      return (
        <Component
          domain={domain}
          country={countryCode.toUpperCase()}
          countryCode={countryCode}
          oneTrustData={state.oneTrust}
          {...props}
        />
      );
    }
    return null;
  };
};
